
function set_language(languageCode) {
    frappe.call({
        method: 'frappe.client.set_value',
        args: {
            doctype: 'User',
            name: frappe.session.user,
            fieldname: 'language',
            value: languageCode
        },
        freeze: true,
		freeze_message: __("Changing Language..."),
        callback: (response) => {
            if (!response.exc) {
                frappe.ui.toolbar.clear_cache()
            } else {
                console.error("Error changing user language:", response.exc);
            }
        }
    });
}


$(document).on("click", "#change_language", (e) => {
    let el = $(e.currentTarget)
    let lang = $(el).data("language")

    set_language(lang)
})